// PaymentSuccessAudioProgram.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../components/layoutNoStripe";
import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../styledComponents/article";
import { PasswordReset } from "../components/Auth0/passwordReset";
import {
  getManagementApiToken,
  checkIfUserExists,
  createAuth0User,
  assignAuth0Role,
} from "../components/Auth0/auth0Helpers";
import { getStripeSession } from "../components/Stripe/stripeHelpers";
import { Spinner, SpinnerContainer } from "../styledComponents/spinner";
import { StyledButton } from "../styledComponents/button";
import { Section } from "../styledComponents/section";
import { SectionProgramSuccess } from "../styledComponents/sectionProgramSuccess";

const PaymentSuccessProgramDivineReversalCodes = ({ location }) => {
  const [customer, setCustomer] = useState({});
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();

  const handlePasswordReset = async (email) => {
    setIsSendingReset(true);
    try {
      const token = await getManagementApiToken();

      const responseText = await response.text(); // Read raw response text

      try {
        const jsonResponse = responseText ? JSON.parse(responseText) : {}; // Safely parse JSON
        if (!response.ok) {
          throw new Error(jsonResponse?.error || "Failed to send password reset email.");
        }
      } catch (error) {
        throw new Error("Unexpected response format from Auth0: " + responseText);
      }

      const response = await fetch(
        `https://${process.env.GATSBY_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            client_id: process.env.GATSBY_AUTH0_MGMT_CLIENT_ID,
            email: email,
            connection: "Username-Password-Authentication",
            redirect_uri: `${window.location.origin}/reset-success`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send password reset email.");
      }

      setMessage("Password reset email sent! Please check your inbox.", "success");
    } catch (error) {
      setMessage(`Error: ${error.message}`, "error");
    } finally {
      setIsSendingReset(false);
    }
  };

  useEffect(() => {
    let timeoutId;
    const processStripeSession = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("sessionID");

      if (!sessionId) {
        setMessage("Error: Session ID not found in URL.", "error");
        setLoadingRoles(false);
        return;
      }

      try {
        console.log("Checking getStripeSession:", typeof getStripeSession);
        const sessionData = await getStripeSession(sessionId);
        console.log('sessionData:', sessionData);
        if (sessionData.customer && sessionData.productsList) {
          setCustomer(sessionData.customer);
          const fullName = sessionData.customer.name || "";
          setCustomerFirstName(fullName.split(" ")[0]);

          const email = sessionData.customer.email;
          console.log("Checking getManagementApiToken:", typeof getManagementApiToken);
          const token = await getManagementApiToken();

          console.log("Checking checkIfUserExists:", typeof checkIfUserExists);
          let userId = await checkIfUserExists(email, token);

          if (!userId) {
            console.log("🚀 User does not exist - creating account.");
            const newUserId = await createAuth0User(
              email,
              fullName.split(" ")[0],
              fullName.split(" ")[1] || "",
              token
            );
          
            if (newUserId) {
              userId = newUserId; // ✅ Now we correctly assign the user_id
              console.log("✅ Auth0 User Created Successfully, userId:", userId);
              // ✅ Ensure loadingRoles is set to false after new user creation
              setLoadingRoles(false);
              // ✅ Set success message for new users
              setMessage("Your account has been created. Please check your email for login details.", "success");
            } else {
              console.error("❌ Error: Auth0 user creation failed or user_id missing.");
              throw new Error("Failed to retrieve user_id after creating user.");
            }
          } else {
            console.log("✅ User exists. User ID:", userId);
            setUserExists(true);

            // If user exists but is NOT logged in, show the message
            // ✅ Show message for returning users who aren't logged in
            if (!isAuthenticated) {
              setLoadingRoles(false);
            }
          }

          console.log("🔍 Assigning role to userId:", userId, typeof userId);

          if (typeof userId !== "string") {
            throw new Error(`❌ Invalid userId detected: ${JSON.stringify(userId)}`);
          }

          // Assign the role to the user
          const productType = sessionData.productsList[0]?.metadata?.auth0_role || "default";
          console.log(`🔄 Assigning role ${productType} to user ${userId}...`);
          console.log("Checking assignAuth0Role:", typeof assignAuth0Role);
          await assignAuth0Role(userId, productType, token);

          console.log(`✅ Role ${productType} assigned successfully to user ${userId}`);
        }
      } catch (error) {
        console.error("❌ Error in processStripeSession:", error);
        setMessage(`Error: ${error.message}`, "error");
        // ✅ Stop the loading spinner even if an error occurs
        setLoadingRoles(false);
      } finally {
        setLoadingRoles(false);
      }
    };

    if (isAuthenticated !== undefined) {
      processStripeSession();
      // ✅ Set a timeout to ensure loadingRoles is reset in case something goes wrong
      timeoutId = setTimeout(() => {
        setLoadingRoles(false);
      }, 5000); // ⏳ Auto-hide spinner after 5 seconds
    }
    return () => clearTimeout(timeoutId); // Cleanup timeout when component unmounts
  }, [isAuthenticated]);

  // If authentication is still being determined, show the spinner
  if (isLoading || loadingRoles) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <SectionProgramSuccess className="program-success">
        <Article itemScope itemType="http://schema.org/Article">
          <ArticleHeader noMargin>
            <h1 itemProp="headline">Your Divine Reversal Codes Are Activated</h1>
          </ArticleHeader>
          <ArticleSection itemProp="articleBody">
            <p style={{ marginBottom: '1.6rem' }}>The shift has already begun.</p>

            <p style={{ marginBottom: '1.6rem' }}>The moment you said yes, the unseen forces that once held you back started to dissolve. Your Divine Reversal Codes subscription is now live, and you have immediate access to powerful tools that will accelerate your healing and transformation.</p>
            {message && <div className={`message ${messageType}`}><p>{message}</p></div>}

            <h2>What You Can Access Right Now</h2>
            <h3>Your Divine Reversal Codes Account</h3>
            <ul>
              <li>{isAuthenticated && userExists ? <span>Visit <a href="/my-account">your account</a> page to access your exclusive bonuses and live session details.</span> : <span><a href="/my-account">Log in</a> now to access your training materials, healing audios, and live session details.</span>}</li>
              <li>Your welcome email contains your login instructions—check your inbox!</li>
            </ul>

            <h3>📆 Upcoming Live Calls with Master Kaye Lee</h3>

            <ul>
              <li>Your first activation sessions are happening soon!</li>
              <li>Save the dates for your first live energy calls and be ready to receive powerful energetic transmissions.</li>
            </ul>


            <h3>Exclusive Bonuses</h3>

<ul>
  <li>Complete Command Healing Program – Unlock the full pre-recorded training to accelerate healing with pinpoint accuracy.</li>
  <li>12 Permissioning Audio Teachings by Karina Grant – Start rewiring your energy for success and effortless transformation.</li>
  <li>The Permission to Sleep Program – Need better rest? Begin clearing stress and resetting your nervous system tonight.</li>
</ul>

<h2 style={{ marginBottom: '1.6rem' }}>Your Next Step: {isAuthenticated && userExists ? <span>Go to Your Account</span> : <span>Log in</span>}</h2>

<p style={{ marginBottom: '1.6rem' }}><a href="/my-account">Click Here to {isAuthenticated && userExists ? <span>Access <a href="/my-account">Your Account</a></span> : <span><a href="/my-account">Log in</a></span>}</a></p>

<p style={{ marginBottom: '1.6rem' }}>Your healing and transformation are already in motion.</p>
<p style={{ marginBottom: '1.6rem' }}>Now, all you have to do is receive.</p>

<p style={{ marginBottom: '1.6rem' }}>✨ Welcome to the Divine Reversal Codes.</p>

          </ArticleSection>
        </Article>
      </SectionProgramSuccess>
    </LayoutNoStripe>
  );
};

export default PaymentSuccessProgramDivineReversalCodes;