import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "./tabs"
import { regular14, regular16, regular18, regular20 } from "./typography";

const SectionProgramSuccess = styled.section`
  ${baseGridStyles}
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: ${props => props.background ? '100%' : 'auto'};
  }
  section {
    padding: 0;
  }
  p{
    margin: 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular18}
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      ${regular20}
    }


  }
`

export {
  SectionProgramSuccess
}